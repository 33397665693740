
export default {
    elements: {
      seo_title: '.js-seo-title',
      seo_description: '.js-seo-description',
      seo_link: '.js-seo-link',
    },
    values: {
      seo_title: null,
      seo_description: null,
    },
    update: function() {
      const self = this;
  
      const meta = {
        seo_title: document.querySelector(self.elements.seo_title).textContent,
        seo_description: document.querySelector(self.elements.seo_description).textContent
      };
      
      const metaDescription = document.querySelector('meta[name="description"]'); // Get the <meta> element with the name "description"
      metaDescription.setAttribute("content", meta.seo_description); // Set the "content" attribute of the <meta> element to meta.seo_description
  
      const metaOgTitle = document.querySelector('meta[property="og:title"]'); // Get the <meta> element with the property "og:title"
      metaOgTitle.setAttribute("content", meta.seo_title); // Set the "content" attribute of the <meta> element to meta.seo_title
      document.querySelector('title').textContent = meta.seo_title; // Set the text content of the <title> element to meta.seo_title
  
      const metaOgDescription = document.querySelector('meta[property="og:description"]'); // Get the <meta> element with the property "og:description"
      metaOgDescription.setAttribute("content", meta.seo_description); // Set the "content" attribute of the <meta> element to meta.seo_description
  
      const metaOgUrl = document.querySelector('meta[property="og:url"]'); // Get the <meta> element with the property "og:url"
      metaOgUrl.setAttribute("content", window.location.href); // Set the "content" attribute of the <meta> element to the current URL
  
      self.values = meta;
    }
  };