


const visible = {
    instance: null,
    element: '.js-visible',
    init() {
        const self = this;
        
        document.querySelectorAll(self.element).forEach(element => {
            self.check(element);
        });
        
        window.addEventListener('scroll', function() { 
            document.querySelectorAll(self.element).forEach(element => {
                self.check(element);
            });
        });
    },
    check(element) {
        const elementRect = element.getBoundingClientRect();
        const isVisible = (
            elementRect.top >= 0 &&
            elementRect.left >= 0 &&
            elementRect.bottom <= (window.innerHeight ||
            document.documentElement.clientHeight) &&
            elementRect.right <= (window.innerWidth || 
            document.documentElement.clientWidth)
        );
        console.log(isVisible);

        if (isVisible) {
            element.classList.add('is-visible');
        }
    }
};

export default visible

