import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import { isLg } from './utils'


const slider = {
    instances: [],
    elements: {
        $swiper: document.querySelectorAll('.js-slider')
    },
    init: function() {

        const self = this;

        const $swiper = document.querySelectorAll('.js-slider');

        console.log('slider');
        if ($swiper.length > 0) {
            
            $swiper.forEach(function($swiper, i) {

                const sliderSlug = $swiper.dataset.sliderSlug;

                switch (sliderSlug) {
                    case 'gallery':
                        self.instances[i] = new Swiper($swiper, {
                            modules: [Navigation, Pagination, Autoplay],
                            loop: true,
                            autoplay: {
                                delay: 4000,
                            },
                            pagination: {
                                el: '.swiper-pagination',
                                clickable: true,
                            },
                        });
                        
                        break;
                    
                    case 'cover-color':
                        self.instances[i] = new Swiper($swiper, {
                            modules: [Autoplay],
                            loop: true,
                            autoplay: {
                                delay: 3000,
                            },
                            effect: 'creative',
                        });

                        // on slide change
                        $swiper.querySelector('.js-slider-color').classList.add('scale-x-100');
                        self.instances[i].on('slideChangeTransitionStart', function () {
                            const $activeSlide = $swiper.querySelector('.swiper-slide-active');
                            $swiper.querySelectorAll('.js-slider-color').forEach(function($color) {
                                $color.classList.remove('scale-x-100');
                            });
                            
                            $activeSlide.querySelector('.js-slider-color').classList.add('scale-x-100');
                        });

                        break;
                }      

            });
        }
    },
    restart: function() {
        const self = this;
        console.log(self.instances);
        if (self.instances > 0) {
            self.instances.forEach(function(instance) {
                console.log(instance.destroy(true, true));
            });

            self.instances = [];
        }

        self.init();
    }
}

export default slider;