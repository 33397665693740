import htmx from 'htmx.org'
import slider from './js/slider';
import lazy from './js/lazy.js'
import toggle from './js/toggle.js'
import htmxEvents from './js/htmxEvents';
import rellax from './js/rellax';
import visible from './js/visible';
import hroom from './js/hroom';
import video from './js/video';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

window.onload = function() {
  htmxEvents.init();
  toggle.init();
  lazy.init();
  slider.init();
  rellax.init();
  visible.init();
  hroom.init();
  video.init();
}

htmx.on("htmx:afterSwap", function(evt) {
  toggle.disable('with-nav');
  lazy.init();

  slider.restart();
  visible.init();
  video.init();

  setTimeout(() => {
    rellax.refresh();
  }, 500);

  window.scrollTo(0, 0);
});
