import Headroom from "headroom.js";

const hroom = {
    instance: null,
    element: '.js-headroom',
    init: function() {
      const self = this,
        $headroom = document.querySelector(self.element);
        console.log($headroom)
        self.instance = new Headroom($headroom, {
        });
        self.instance.init();
    },
  };
  
  export default hroom