import meta from './meta';

export default {
    elements: {
      main: '.js-htmx-main',
      content: '.js-htmx-content',
      click: '.js-htmx-click',
      status: '.js-htmx-status',
      langLink: '.js-htmx-lang-link',
      onLoad: '.js-htmx-onload',
      $activeClick: null,
    },
    currentTarget: '.js-htmx-main',
    duration: 400,
    init: function() {
      const self = this;
  
      self.events();
      console.log('htmxEvents.init');
      Array.from(document.querySelectorAll(self.elements.onLoad)).forEach(function(element){
        const url = element.value;
        const target = element.getAttribute('hx-target');
  
        if (url) {
          htmx.ajax('GET', url, {target: target, swap:'innerHTML'}).then(function(){
            document.querySelector(target).classList.add('is-loaded');
          });
        }
      });
    },
    events: function() {
      const self = this;
      document.querySelector('body').addEventListener('click', function(event) {
        const target = event.target.closest(self.elements.click);
        if (!target) return;
        self.elements.$activeClick = target;
        self.currentTarget = target.getAttribute('hx-target');
  
        self.hideTarget();
      });
  
      document.querySelector('body').addEventListener('htmx:historyRestore', function() {
        self.showTarget();
      });
  
      document.querySelector('body').addEventListener('htmx:afterSwap', function() {
        self.showTarget();
      });
    },
    hideTarget: function() {
      const self = this,
        $target = document.querySelector(self.currentTarget);
      console.log($target);
  
      if ($target.classList.contains('js-htmx-main')) {
        Array.from(document.querySelectorAll(self.elements.click)).forEach(function(element) {
          element.classList.remove('is-active');
        });
  
        Array.from(document.querySelectorAll(self.elements.status)).forEach(function(element) {
          element.classList.remove('is-active');
        });
  
        self.elements.$activeClick.classList.add('is-active');
  
        if (self.currentTarget == self.elements.main) {
  
          setTimeout(() => {
            window.scrollTo(0, 0);
            /*
            document.body.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            */
          }, self.duration / 2);
        }
      } else {
        Array.from(document.querySelectorAll(self.elements.click)).forEach(function(element) {
          if (element.getAttribute('hx-target') == self.currentTarget) {
            element.classList.remove('is-active');
          }
        });
        self.elements.$activeClick.classList.add('is-active');
      }
  
      $target.classList.remove('is-loaded');
    },
    showTarget: function() {
      const self = this,
        $target = document.querySelector(self.currentTarget);
  
      Array.from(document.querySelectorAll(self.elements.langLink)).forEach(function(element) {
        const language = element.getAttribute('data-language');
        console.log(language)
        const thisLanguageHref = document.querySelector(meta.elements.seo_link+'[data-language="'+language+'"]').value;
  
        console.log(language, thisLanguageHref);
  
        element.setAttribute('href', thisLanguageHref);
      });
   
      setTimeout(() => {
        $target.classList.add('is-loaded');
        // pages.update();
        meta.update();
      }, self.duration);
    },
    restart: function() {
      const self = this;
  
      document.querySelector('body').removeEventListener('click', function(event) {
        const target = event.target.closest(self.elements.click);
        if (!target) return;
        self.elements.$activeClick = target;
        self.currentTarget = target.getAttribute('hx-target');
  
        self.hideTarget();
      });
  
      document.querySelector('body').removeEventListener('htmx:historyRestore', function() {
        self.showTarget();
      });
  
      document.querySelector('body').removeEventListener('htmx:afterSwap', function() {
        self.showTarget();
      });
  
      self.events();
    }
  };
  
  