const toggle = {
    elements: {
        toggle: '.js-toggle',
    },
    init() {
        const self = this;
        self.click();
    },
    click() {
        const self = this;

        document.addEventListener("click", (event) => {
            if (!event.target.matches(self.elements.toggle)) return false;
            
            const $toggle = event.target,
                    slug = $toggle.dataset.toggle;
            
            self.toggle(slug);
        });
    },
    toggle(slug) {
        document.querySelectorAll('[data-toggle="'+slug+'"]').forEach(function(element) {
            element.classList.toggle('is-toggled');
        });
    },
    enable(slug) {
        document.querySelectorAll('[data-toggle="'+slug+'"]').forEach(function(element) {
            element.classList.add('is-toggled');
        });
    },
    disable(slug) {
        document.querySelectorAll('[data-toggle="'+slug+'"]').forEach(function(element) {
            element.classList.remove('is-toggled');
        });
    }
};

export default toggle

