import Rellax from 'rellax';

const rellaxInstance = {
    rellax: null,
    elements: {
        rellax: '.js-rellax',
    },
    init() {
        const self = this;
        self.rellax = new Rellax(self.elements.rellax);
    },
    refresh() {
        const self = this;
        self.init();
    }
};

export default rellaxInstance

