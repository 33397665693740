import Plyr from 'plyr';

const video = {
    instance: null,
    element: '.js-video',
    init: function() {
        const self = this;
        if (!document.querySelector(self.element)) {
            return;
        }
        self.instance = new Plyr(self.element, {
        });
    },
  };
  
  export default video